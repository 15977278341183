dmx.Component('tagify', {

    extends: 'form-element',

    initialData: {
        items: [],
        values: []
    },

    attributes: {
        settings: {
            type: Object,
            default: {}
        },

        data: {
            type: Array,
            default: null
        },

        'tag-value': {
            type: String,
            default: '$value'
        },

        'tag-text': {
            type: String,
            default: '$value'
        },

        'tag-secondary': {
            type: String,
            default: null
        },

        'tag-image': {
            type: String,
            default: null
        },

        'tag-class': {
            type: String,
            default: null
        },

        'tag-count': {
            type: String,
            default: null
        },

        'tag-readonly': {
            type: String,
            default: null
        },

        nocustom: {
            type: Boolean,
            default: false
        },

        readonly: {
            type: Boolean,
            default: false
        },

        delimiters: {
            type: String,
            default: ','
        },

        duplicates: {
            type: Boolean,
            default: false
        },

        noinput: {
            type: Boolean,
            default: false
        },

        'max-tags': {
            type: Number,
            default: Infinity
        },

        loading: {
            type: Boolean,
            default: false
        },

        mode: {
            type: String,
            default: null // select/mix
        },

        notrim: {
            type: Boolean,
            default: false
        },

        noautocomplete: {
            type: Boolean,
            default: false
        },

        'keep-invalid': {
            type: Boolean,
            default: false
        },

        'skip-invalid': {
            type: Boolean,
            default: false
        },

        'min-chars': {
            type: Number,
            default: 2
        },

        'case-sensitive': {
            type: Boolean,
            default: false
        },

        'max-items': {
            type: Number,
            default: 10
        },

        'no-fuzzy-search': {
            type: Boolean,
            default: false
        },

        'no-accented-search': {
            type: Boolean,
            default: false
        },

        'dropdown-position': {
            type: String,
            default: 'all',
            enum: ['all', 'text', 'input']
        },

        'highlight-first': {
            type: Boolean,
            default: false
        },

        'no-close-on-select': {
            type: Boolean,
            default: false
        },

        pattern: {
            type: String,
            default: null
        }
    },

    methods: {
        addEmptyTag: function() {
            this.tagify.addEmptyTag();
        },

        addTags: function(tags, clear, skipInvalids) {
            this.tagify.addTags(tags, clear, skipInvalids);
            this.onchange();
        },

        removeTags: function(tags) {
            this.tagify.removeTags(tags);
            this.onchange();
        },

        removeAllTags: function() {
            this.tagify.removeAllTags();
            this.onchange();
        },
    },

    events: {
        change: Event,
        add: Event,
        remove: Event,
        invalid: Event,
        input: Event,
        focus: Event,
        blur: Event,
        noresults: Event,
    },

    onchange: function(event) {
        var tags = this.tagify.getCleanValue();
        
        this.set('items', tags.map(function(tag) {
            return tag.__item;
        }))

        this.set('values', tags.map(function(tag) {
            return tag.value;
        }));
    },

    getWhitelist: function() {
        if (!Array.isArray(this.props.data)) return [];

        return this.props.data.map(function(item) {
            var scope = dmx.DataScope(item, this);
            var tagData = {
                __dmx: true,
                __item: item,
                value: dmx.parse(this.props['tag-value'], scope),
                label: dmx.parse(this.props['tag-text'], scope)
            };

            if (item.style) {
                tagData.style = item.style;
            }

            if (this.props['tag-secondary']) {
                tagData.secondary = dmx.parse(this.props['tag-secondary'], scope);
            }

            if (this.props['tag-image']) {
                tagData.image = dmx.parse(this.props['tag-image'], scope);
            }

            if (this.props['tag-class']) {
                tagData.class = dmx.parse(this.props['tag-class'], scope);
            }

            if (this.props['tag-count']) {
                tagData.count = dmx.parse(this.props['tag-count'], scope);
            }

            if (this.props['tag-readonly']) {
                tagData.readonly = !!dmx.parse(this.props['tag-readonly'], scope);
            }

            return tagData;
        }, this);
    },

    templates: {
        wrapper: function(input, _s) {
            return '<tags class="' + _s.classNames.namespace + ' ' +
                (_s.mode ? _s.classNames[_s.mode + 'Mode'] : '') + ' ' +
                input.className + '"' +
                (_s.readonly ? ' readonly' : '') +
                (_s.disabled ? ' disabled' : '') +
                (_s.required ? ' required' : '') +
                (input.hasAttribute('dmxDomId') ? ' dmxDomId="' + input.getAttribute('dmxDomId') + '"' : '') +
                (input.hasAttribute('style') ? ' style="' + input.getAttribute('style') + '"' : '') +
                ' tabIndex="-1">' +
                '<span' + (!_s.readonly && _s.userInput ? ' contenteditable' : '') +
                ' tabIndex="0" data-placeholder="' + (_s.placeholder || '&#8203;') + '"' +
                ' aria-placeholder="' + (_s.placeholder || '') + '"' +
                ' class="' + _s.classNames.input + '"' +
                ' role="textbox"' +
                ' aria-autocomplete="both"' +
                ' aria-multiline="' + (_s.mode == 'mix' ? true : false) + '"></span>' +
                '&#8203;</tags>';
        },

        tag: function(tagData) {
            var _s = this.settings;

            return '<tag ' +
                //this.getAttributes(tagData) +
                (tagData.readonly ? ' readonly' : '') +
                ' title="' + (tagData.title || tagData.value) + '"' +
                ' contenteditable="false"' +
                ' spellcheck="false"' +
                ' tabindex="' + (_s.a11y.focusableTags ? 0 : -1) + '"' +
                ' class="' + _s.classNames.tag + ' ' + (tagData.class || '') + '"' +
                ' style="white-space:nowrap;' + (tagData.style || '') + '"' +
                '>' +
                '<x' +
                ' title=""' +
                ' class="' + _s.classNames.tagX + '"' +
                ' role="button"' +
                ' aria-label="remove tag"' +
                '></x>' +
                '<div' +
                (_s.mode != 'select' ? ' style="display:flex;align-items:center;"' : '') +
                '>' +
                (tagData.image ?
                '<img' +
                ' onerror="this.style.visibility=\'hidden\'"' +
                ' src="' + tagData.image + '"' +
                ' style="height:var(--tag-img-size, 1em);margin-right:.3em;pointer-events:none;"' +
                '>' :
                '') +
                '<span' +
                ' class="' + _s.classNames.tagText + '"' +
                '>' + (tagData[_s.tagTextProp] || tagData.value) + '</span>' +
                '</div>' +
                '</tag>';
        },

        dropdownItem: function(item) {
            var style = getComputedStyle(this.DOM.originalInput);
            
            var gap = style.getPropertyValue('--item-gap') || '.3em';
            var img_size = style.getPropertyValue('--item-img-size') || '1em';
            var text_color = style.getPropertyValue('--item-text-color') || 'inherit';
            var sec_color = style.getPropertyValue('--item-sec-color') || 'inherit';
            var sec_size = style.getPropertyValue('--item-sec-size') || '.75em';
            var count_color = style.getPropertyValue('--item-count-color') || 'inherit';

            return '<div ' +
                // this.getAttributes(item) +
                ' value="' + item.value + '"' +
                ' class="' + this.settings.classNames.dropdownItem +
                (item.class ? ' ' + item.class : '') + '"' +
                ' style="display: flex; align-items: center; gap: var(--item-gap, ' + gap + ')"' +
                ' tabindex="0" role="option">' +
                (item.image ?
                '<img' +
                ' onerror="this.style.visibility=\'hidden\'"' +
                ' src="' + item.image + '"' +
                ' style="height:var(--item-img-size, ' + img_size + ');pointer-events:none;"' +
                '>' :
                '') +
                '<div style="color: var(--item-text-color, ' + text_color + ')">' + (item.label || item.value) +
                (item.secondary ? '<br><span style="color: var(--item-sec-color, ' + sec_color + '); font-size: ' + sec_size + '">' + item.secondary + '</span>' : '') +
                '</div>' +
                (item.count ? ' <div style="color:var(--item-count-color, ' + count_color+ ')"> (' + item.count + ')</div>' : '') +
                '</div>';
        }
    },

    render: function(node) {
        dmx.Component('form-element').prototype.render.call(this, node);

        var self = this;

        if (this.$node.form && this.$node.name) {
            var name = this.$node.name;

            this.$node.form.addEventListener('formdata', function(event) {
                var formData = event.formData;
                
                if (self.props.mode != 'select' && self.props.mode != 'mix') {
                    formData.delete(name);

                    if (Array.isArray(self.data.values)) {
                        var _name = name;
                        if (!/\[\]$/.test(_name)) _name += '[]';

                        self.data.values.forEach(function(value) {
                            formData.append(_name, value);
                        });
                    }
                }
            });

            this.$node.form.addEventListener('reset', function(event) {
                if (self.tagify) {
                    self.tagify.removeAllTags();
                    self.onchange();
                }
            });
        }
        
        this.tagify = new Tagify(this.$node, dmx.extend({
            enforceWhitelist: this.props.nocustom,
            whitelist: this.getWhitelist(),
            blacklist: this.props.blacklist,
            tagTextProp: 'label',
            delimiters: this.props.delimiters,
            duplicates: this.props.duplicates,
            userInput: !this.props.noinput,
            maxTags: this.props['max-tags'],
            mode: this.props.mode,
            trim: !this.props.notrim,
            keepInvalidTags: this.props['keep-invalid'],
            skipInvalid: this.props['skip-invalid'],
            pattern: this.props.pattern && this.props.pattern.startsWith('/') ? new RegExp(this.props.pattern.replace(/^\/|\/$/, '')) : this.props.pattern,
            autoComplete: {
                enabled: !this.props.noautocomplete,
                rightKey: !this.props.noautocomplete
            },
            dropdown: {
                enabled: this.props['min-chars'] >= 0 ? this.props['min-chars'] : false,
                searchKeys: ['label'],
                caseSensitive: this.props['case-sensitive'],
                maxItems: this.props['max-items'],
                fuzzySearch: !this.props['no-fuzzy-search'],
                accentedSearch: !this.props['no-accented-search'],
                position: this.props['dropdown-position'],
                highlightFirst: this.props['highlight-first'],
                closeOnSelect: !this.props['no-close-on-select']
            },
            originalInputValueFormat: function(tags) {
                return tags.map(function(tag) {
                    return tag.value;
                }).join(self.props.delimiters[0]);
            },
            transformTag: function(tag) {
                delete tag.class;
                delete tag.count;
                delete tag.image;
                delete tag.__dmx;
                delete tag.__item;

                var found = self.getWhitelist().find(function(data) {
                    return data.value == tag.value || data.label == tag.value;
                });

                if (found) {
                    Object.assign(tag, found);
                }
            },
            templates: this.templates
        }, this.props.settings));

        if (this.props.nocustom && this.props.data == null) {
            this.tagify.loading(true);
            this.tagify.setDisabled(true);
        } else {
            this.tagify.loading(this.props.loading);
        }

        this.tagify.setReadonly(this.props.readonly);

        this.tagify.on('change', this.onchange.bind(this));
        this.tagify.on('change', this.dispatchEvent.bind(this, 'change'));
        
        this.tagify.on('add', function(event) {
            self.dispatchEvent('add', null, {
                item: event.detail.data.__item,
                value: event.detail.data.value,
                isCustom: !event.detail.data.__item
            });
        });
        
        this.tagify.on('remove', function(event) {
            self.dispatchEvent('remove', null, {
                item: event.detail.data.__item,
                value: event.detail.data.value,
                isCustom: !event.detail.data.__item
            });
        });
        
        this.tagify.on('invalid', function(event) {
            self.dispatchEvent('invalid', null, {
                value: event.detail.data.value,
                message: event.detail.message
            });
        });
        
        this.tagify.on('input', function(event) {
            self.dispatchEvent('input', null, {
                value: event.detail.value,
                isValid: event.detail.isValid
            });
        });
        
        this.tagify.on('focus', this.dispatchEvent.bind(this, 'focus'));
        
        this.tagify.on('blur', this.dispatchEvent.bind(this, 'blur'));
        
        this.tagify.on('dropdown:noMatch', function(event) {
            self.dispatchEvent('noresults', null, {
                value: event.detail.value
            });
        });

        this.update({});
    },

    update: function(props) {
        if (props.disabled != this.props.disabled) {
            this.tagify.setDisabled(this.props.disabled);
        }

        if (props.readonly != this.props.readonly) {
            this.tagify.setReadonly(this.props.readonly);
        }

        if (!dmx.equal(props.data, this.props.data)) {
            this.tagify.whitelist = this.getWhitelist();

            if (this.props.nocustom) {
                this.$node.defaultValue = this.props.value || '';
                this.setValue(this.props.value);
                this.tagify.loadOriginalValues(this.props.value);
                this.onchange();
                this.tagify.loading(this.props.loading);
                this.tagify.setDisabled(this.props.disabled);
            } else {
                dmx.array(this.tagify.getTagElms()).forEach(function(tag) {
                    var tagData = this.tagify.tagData(tag);
                    
                    if (!tagData.__dmx) {
                        var found = this.getWhitelist().find(function(data) {
                            return data.value == tagData.value || data.label == tagData.value;
                        });
    
                        if (found) {
                            Object.assign(tagData, found);
                            this.tagify.replaceTag(tag, tagData);
                        }
                    }
                }, this);
                this.onchange();
            }
        }

        if (!dmx.equal(props.value, this.props.value)) {
            this.$node.defaultValue = this.props.value || '';
            this.setValue(this.props.value);
            this.tagify.loadOriginalValues(this.props.value);
            this.onchange();
        }

        if (props.loading != this.props.loading) {
            this.tagify.loading(this.props.loading);
        }

        this.updateData();
    },

    destroy: function() {
        this.tagify.destroy();
    }

});